<style scoped>
body {
  background-color: white !important;
  color: black !important;
}
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: A4 portrait;
}

/* Set the font size to 12px for printing */
@media print {
  body {
    font-size: 12px;
  }

  .page-break {
    page-break-before: always;
  }

  @page :footer {
    display: none;
  }
}

#logo {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 200px;
  height: 100px;
}

#title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: none;
}

.border-dash-t {
  border-top: 2px dashed;
}

.border-dash-b {
  border-bottom: 2px dashed;
}

#no-border {
  border: none;
}

#table-list {
  margin-top: 80px;
}

#info {
  margin-top: 20px;
  width: 40%;
  float: left;
  margin-bottom: 20px;
}

.space-for-logo {
  width: 200px;
  height: 100px;
}

table {
  background-color: white !important;
  color: black !important;
}

table tr {
  font-family: "Courier";
  line-height: normal;
  font-size: 11px;
}
</style>

<template>
  <div>
    <div
      class="page-break"
      v-for="(dataInvoice, index) in dataInvoices"
      :key="index"
    >
      <table width="100%">
        <tr>
          <td width="50%">
            <table style="font-weight: bold">
              <tr>
                <td>PT. SINARMAS DISTRIBUSI NUSANTARA</td>
              </tr>

              <tr>
                <td>{{ dataInvoice.territory_area_address }}, {{ dataInvoice.territory_area_sub_district }}, {{ dataInvoice.territory_area_district }}, </td>
              </tr>
              <tr>
                <td>{{ dataInvoice.territory_area_city }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>{{ dataInvoice.company_phone }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>NPWP SDN: {{ dataInvoice.company_npwp }}</td>
              </tr>
              <tr>
                <td>NITKU SDN: {{ dataInvoice.company_nitku }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Payer :</td>
              </tr>
              <tr>
                <td>{{ dataInvoice.customer_name }}</td>
              </tr>
              <tr>
                <td>{{ dataInvoice.customer_address }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>NPWP Customer : {{ dataInvoice.customer_npwp }}</td>
              </tr>
              <tr>
                <td>NITKU Customer : {{ dataInvoice.customer_nitku }}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </td>
          <td width="50%">
            <table>
              <tr>
                <td style="text-align: center; font-size: large">
                  <strong>FAKTUR</strong>
                </td>
              </tr>
              <tr>
                <td style="text-align: center; font-size: large">
                  <strong>(BILLING)</strong>
                </td>
              </tr>
            </table>
            <table style="border-top: dashed">
              <tr>
                <td>Billing No.</td>
                <td>:</td>
                <td>{{ dataInvoice.invoice_code }}</td>
              </tr>
              <tr>
                <td>Billing Date</td>
                <td>:</td>
                <td>{{ dataInvoice.invoice_date }}</td>
              </tr>
              <tr>
                <td>Order No.</td>
                <td>:</td>
                <td>{{ dataInvoice.sales_order_code }}</td>
              </tr>
              <tr>
                <td>Order Date</td>
                <td>:</td>
                <td>{{ dataInvoice.sales_order_date }}</td>
              </tr>
              <tr>
                <td>Customer No.</td>
                <td>:</td>
                <td>{{ dataInvoice.customer_code }}</td>
              </tr>
              <tr>
                <td>Sales Office</td>
                <td>:</td>
                <td>{{ dataInvoice.territory_code }}</td>
              </tr>
              <tr>
                <td>Condition Delivery</td>
                <td>:</td>
                <td>{{ dataInvoice.delivery_type_name }}</td>
              </tr>
              <tr>
                <td>Term of Payment</td>
                <td>:</td>
                <td>Due within {{ dataInvoice.due_invoice_period }} day(s)</td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td>:</td>
                <td>{{ dataInvoice.invoice_due_date }}</td>
              </tr>
              <tr>
                <td>Tax Status/Currency</td>
                <td>:</td>
                <td>PKP / IDR</td>
              </tr>
              <tr>
                <td>Sales Code/Salesman</td>
                <td>:</td>
                <td>
                  {{ dataInvoice.sales_code }} / {{ dataInvoice.sales_name }}
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table class="remove_border">
        <thead>
          <tr class="border-dash-t border-dash-b">
            <th style="vertical-align: middle; text-align: left">Product</th>
            <th style="vertical-align: middle; text-align: left">Quantity</th>
            <th style="vertical-align: middle; text-align: left">UoM</th>
            <th style="vertical-align: middle; text-align: left">
              Price Value
            </th>
            <th style="vertical-align: middle; text-align: left">
              Gross Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="idx" v-for="(dataLine, idx) in dataInvoice.lines">
            <td class="px-3" style="vertical-align: middle; text-align: left">
              {{ dataLine.item_name }}
            </td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ dataLine.qty }}
            </td>
            <td class="px-3" style="vertical-align: middle; text-align: left">
              {{ dataLine.item_unit }}
            </td>
            <td class="px-3" style="vertical-align: middle; text-align: left">
              {{ formatNumber(dataLine.price) }} / 1 {{ dataLine.item_unit }}
            </td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataLine.price * dataLine.qty) }}
            </td>
          </tr>
          <tr class="border-dash-t">
            <td></td>
            <td></td>
            <td>Gross Total</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.subtotal) }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Discount</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.discount) }} -
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Subtotal</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.subtotal - dataInvoice.discount) }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>DP</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              0
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>DPP</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.subtotal - dataInvoice.discount) }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>PPN {{ dataInvoice.tax_rate }}%</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.tax_amount) }}
            </td>
          </tr>
          <tr class="border-dash-b">
            <td></td>
            <td></td>
            <td>Total</td>
            <td></td>
            <td class="px-3" style="vertical-align: middle; text-align: right">
              {{ formatNumber(dataInvoice.total) }}
            </td>
          </tr>
          <tr class="border-dash-b">
            <td colspan="5">Say :</td>
          </tr>
          <tr>
            <td colspan="5">
              Sales Order : {{ dataInvoice.sales_order_code }}
            </td>
          </tr>
          <tr>
            <td colspan="5">Customer PO. No. :</td>
          </tr>
          <tr class="border-dash-b">
            <td colspan="5">Message :</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tr>
          <td><strong>Notes:</strong></td>
        </tr>
        <tr>
          <td>
            This is a computer generated document and no signature is required
          </td>
        </tr>
        <tr>
          <td><strong>Bank Account :</strong></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  data() {
    return {
      id: null,
      deliveryOrderCode: "",
      doIds: [],
      table: [],
      selectedDestWarehouse: null,
      // logoSDN: logoSDN,
      // data: {
      //     // Company: {},
      //     // Customer: {},
      //     // DeliverOrder: [],
      // },
      datas: [],
      data: {},

      dataInvoices: [],
    };
  },
  mounted() {
    var now = moment();
    now.add(1, "days");

    this.reqDeliveryDate = now.toDate();
    this.eta = now.toDate();

    this.deliveryOrderCode = window.opener.delivery_order_code;
    if (this.deliveryOrderCode != "") {
      // this.getData()
      this.print();
    }
  },
  methods: {
    async print() {
      const tempTitle = document.title;
      await this.getData();
      await window.print();
      document.title = tempTitle;
    },
    async getData() {
      this.$vs.loading();
      let respDataInvoice = await this.$http.get(
        `/api/wms/v1/simple-outbound-planner/print-invoice`,
        {
          params: {
            delivery_order_code: this.deliveryOrderCode,
          },
        }
      );

      if (respDataInvoice.code == 200) {
        console.log("respDataInvoice", respDataInvoice);
        this.dataInvoices = respDataInvoice.data;
        document.title = "Invoice " + this.deliveryOrderCode;
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD/MM/YYYY");
      };
    },
    formatNumber: () => {
      return (val) => {
        console.log(val);
        if (val == null) {
          return 0;
        }

        return Intl.NumberFormat("id", { minimumFractionDigits: 0 }).format(
          val
        );
      };
    },
  },
};
</script>
